import userListingsAdapter from '@/features/listing/requests/adapters/user-listings-adapter';
import getListingsVerification from '@/features/listing/requests/get-listings-verification';
import { type ListingType } from '@/features/listing/types/listing-types';
import { type ListingsVerificationCollection } from '@/features/listing/types/listing-verification-types';
import { type UserListingsDataResponse } from '@/features/listing/types/user-listings-response-types';
import { type UserListingsData } from '@/features/listing/types/user-listings-types';
import { type User } from '@/features/user/types/user-types';
import sendHttpError from '@/libs/logger/requests/send-http-error';

interface GetUserListingsParams {
  userId: User['id'],
  bearerToken?: string | null,
  pageNumber?: number,
  pageSize?: number,
  listingTypes?: ListingType[],
}

export default async function getUserListings({
  bearerToken,
  listingTypes,
  pageNumber = 1,
  pageSize = 1,
  userId,
}: GetUserListingsParams): Promise<UserListingsData | null> {
  const headers: HeadersInit = {};
  const queryParams = new URLSearchParams({
    page_number: String(pageNumber),
    page_size: String(pageSize),
    user_id: String(userId),
  });

  if (listingTypes) {
    queryParams.set('listing_type', listingTypes.join(','));
  }

  if (bearerToken) {
    headers.Authorization = `Bearer ${bearerToken}`;
  }

  const url = `${process.env.NEXT_PUBLIC_API_URL}/v1/userlistings?${queryParams.toString()}`;

  const userListingsResponse = await fetch(
    url,
    {
      cache: 'no-store',
      headers,
    },
  );

  if (!userListingsResponse.ok) {
    sendHttpError({
      baseName: 'Get user listings',
      functionName: 'getUserListings',
      method: 'GET',
      requestHeaders: headers,
      statusCode: userListingsResponse.status,
      url,
    });

    return null;
  }

  const userListingsResponseData = await userListingsResponse.json() as UserListingsDataResponse;

  let userListingsVerification: ListingsVerificationCollection | null = null;

  if (bearerToken && userListingsResponseData.listings.length > 0) {
    const userListingsIds = userListingsResponseData.listings.map((listing) => listing.listing_id);
    userListingsVerification = await getListingsVerification({
      listingsIds: userListingsIds,
      userBearerToken: bearerToken,
    });
  }

  return userListingsAdapter(userListingsResponseData, userListingsVerification);
}
