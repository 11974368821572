import { type GeoJSONSource } from '@maptiler/sdk';

import {
  type listingApartmentDetailsAdapter,
  type listingShareDetailsAdapter,
} from '@/features/listing/requests/adapters/listing-adapter';
import { type ListingVerification } from '@/features/listing/types/listing-verification-types';
import {
  type Coordinates,
  type PlaceBoundingBox,
} from '@/features/place/types/place-types';
import { type VerificationStatus } from '@/features/user/types/user-id-verification-types';
import { type User } from '@/features/user/types/user-types';
import { type ImageThumbnail } from '@/shared/types/image-thumbnail-types';

export interface Listing {
  description: string,
  refreshed: Date,
  images: ImageThumbnail[],
  id: number,
  title: string,
  addressFormatted: string,
  addressFull: string,
  calendar: {
    dateIn: Date,
  },
  rates: {
    currency: string,
    monthlyRate: number,
    requestedCurrency: string,
    requestedMonthlyRate: number,
    requestedCurrencyPrefix?: string,
    requestedCurrencyPostfix?: string,
  },
  addressVerificationStatus: VerificationStatus,
  isNew: boolean,
  isBookmarked: boolean,
  geoLocation: ListingGeoLocation,
  listingTypeId: ListingTypeId,
  detailsRoom: ReturnType<typeof listingShareDetailsAdapter>,
  detailsApartment: ReturnType<typeof listingApartmentDetailsAdapter> | null,
  location: ListingPlaceDetails,
  isDataMissing?: boolean,
  verificationDetails?: ListingVerification | null;
}

interface ListingPlaceDetails {
  country: string | null;
  city: string | null;
  region: string | null;
  addressLine: string | null;
  postalCode: string | number | null;
}

// type ShareDetails = Record<string, string | number | >

export interface ListingWithUser extends Listing {
  user: User,
}

export interface ListingGeoLocation {
  latitude: Coordinates['latitude'],
  longitude: Coordinates['latitude'],
  latitudeNorthEast: PlaceBoundingBox['latitudeNorthEast'],
  latitudeSouthWest: PlaceBoundingBox['latitudeSouthWest'],
  longitudeNorthEast: PlaceBoundingBox['longitudeNorthEast'],
  longitudeSouthWest: PlaceBoundingBox['longitudeSouthWest'],
}

export interface ListingsCollection {
  items: ListingWithUser[],
  count: number,
  averageMonthRent: number,
}

export interface ListingMapFeature {
  geometry: {
    type: 'Point',
    coordinates: [Coordinates['longitude'], Coordinates['latitude']],
  }
  properties: {
    listingId: Listing['id'],
  }
  type: 'Feature'
}

export interface ListingMapFeatureCollection {
  type: 'FeatureCollection',
  features: ListingMapFeature[],
}

export type HaveShareUrl =
    'rooms-for-rent'
    | 'rooms-to-rent'
    | 'flat-share'
    | 'pg'
    | 'flatshares'
    | 'accommodations'
    | 'rooms-to-let'
    | 'haveshare';

export type HaveApartmentUrl =
    'apartments-for-rent'
    | 'apartments-to-rent'
    | 'apartments'
    | 'haveapartment';

export type NeedRoomUrl = 'roommates' | 'flatmates' | 'needroom';

export type NeedApartmentUrl = 'tenants' | 'needapartment';

export type DefaultListingUrl = 'rooms-for-rent' | 'apartments-for-rent' | 'roommates' | 'tenants';

export type ListingUrl = HaveShareUrl | HaveApartmentUrl | NeedRoomUrl | NeedApartmentUrl;

export type ListingType = 'haveshare' | 'haveapartment' | 'needroom' | 'needapartment';

export type PreviewListingsData = PointWithMultipleListings | PointWithSingleListing;

interface PointWithMultipleListings {
  listingsSource: GeoJSONSource;
  clusterId: number;
  listingsCount: number;
  listingId: null;
}

interface PointWithSingleListing {
  listingsSource: null;
  clusterId: null;
  listingsCount: 0;
  listingId: number;
}

export enum ListingTypeId {
  haveshare = 2,
  haveapartment = 5,
  needroom = 1,
  needapartment = 4,
}

export type ListingDataView = 'isCard' | 'isDetailed' | 'isUserListings';

export type ListingCardView = 'grid' | 'list';
