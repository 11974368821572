import listingsVerificationAdapter from '@/features/listing/requests/adapters/listings-verification-adapter';
import { type Listing } from '@/features/listing/types/listing-types';
import { type ListingVerificationCollectionResponse } from '@/features/listing/types/listing-verification-responce-types';
import { type ListingsVerificationCollection } from '@/features/listing/types/listing-verification-types';
import sendHttpError from '@/libs/logger/requests/send-http-error';

interface ListingVerificationRequestProps {
  userBearerToken: string,
  listingsIds: Listing['id'][],
}

export default async function getListingsVerification(requestProps: ListingVerificationRequestProps): Promise<ListingsVerificationCollection> {
  const {
    listingsIds,
    userBearerToken,
  } = requestProps;
  const url = `${process.env.NEXT_PUBLIC_API_URL}/v2/bgcheck/listing_verification/listing?listing_ids=${listingsIds.join(',')}`;
  const headers: HeadersInit = { Authorization: `Bearer ${userBearerToken}` };

  const listingsVerificationResponse = await fetch(url, {
    cache: 'no-store',
    headers,
    method: 'GET',
  });

  // TODO: rewrite to flow without break APP
  if (!listingsVerificationResponse.ok) {
    sendHttpError({
      baseName: 'Get logged user listings verification',
      functionName: 'getListingsVerification',
      method: 'GET',
      statusCode: listingsVerificationResponse.status,
      url,
    });

    throw new Error('Failed to fetch logged user listings verification');
  }

  const listingsVerificationResponseData = await listingsVerificationResponse.json() as ListingVerificationCollectionResponse;

  return listingsVerificationAdapter(listingsVerificationResponseData);
}
