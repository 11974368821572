import { type ListingVerificationCollectionResponse } from '@/features/listing/types/listing-verification-responce-types';
import { type ListingsVerificationCollection } from '@/features/listing/types/listing-verification-types';

export default function listingsVerificationAdapter(listingsVerificationCollectionResponse: ListingVerificationCollectionResponse): ListingsVerificationCollection {
  const listingsVerification: ListingsVerificationCollection = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const [listingsId,
    listingsVerificationResponse] of Object.entries(listingsVerificationCollectionResponse.data)) {
    listingsVerification[Number(listingsId)] = listingsVerificationResponse ? {
      createdDate: listingsVerificationResponse.created_date,
      documentType: listingsVerificationResponse.document_type,
      // eslint-disable-next-line no-underscore-dangle
      id: listingsVerificationResponse._id,
      listingId: listingsVerificationResponse.listing_id,
      mediaType: listingsVerificationResponse.media_type,
      status: listingsVerificationResponse.status,
      updatedDate: listingsVerificationResponse.updated_date,
      userId: listingsVerificationResponse.user_id,
    } : null;
  }

  return listingsVerification;
}
