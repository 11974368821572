import {
  type AMENITIES_APARTMENT,
  type APT_SIZE_UNITS,
  type BATHROOMS,
  type BEDROOMS,
} from '@/features/listing/constants/listing-details-constants';
import photoAdapter from '@/features/listing/requests/adapters/photo-adapter';
import {
  type Listing,
  ListingTypeId,
} from '@/features/listing/types/listing-types';
import { type ListingResponse } from '@/features/listing/types/listings-search-response-types';
import { VerificationStatus } from '@/features/user/types/user-id-verification-types';

interface ApartmentDetails {
  apartmentAmenities: null | (keyof typeof AMENITIES_APARTMENT)[];
  bedroomsID: null | keyof typeof BEDROOMS;
  bathroomsID: null | keyof typeof BATHROOMS;
  aptSizeUnits: null | keyof typeof APT_SIZE_UNITS;
  aptSize: null | number;
  isFurnished: null | boolean;
}

export function listingShareDetailsAdapter(shareDetails: ListingResponse['share_details']) {
  if (!shareDetails) {
    return null;
  }

  return {
    agePreference: shareDetails.age_preference_max && shareDetails.age_preference_min ? `${String(shareDetails.age_preference_min)} - ${String(shareDetails.age_preference_max)}` : null,
    agePreferenceMax: shareDetails.age_preference_max,
    agePreferenceMin: shareDetails.age_preference_min,
    buildingType: shareDetails.building_type,
    cleanliness: shareDetails.cleanliness,
    foodPreference: shareDetails.food_preference,
    getUp: shareDetails.get_up,
    goToBed: shareDetails.go_to_bed,
    householdAge: shareDetails.household_age_max && shareDetails.household_age_min ? `${String(shareDetails.household_age_min)} - ${String(shareDetails.household_age_max)}` : null,
    householdAgeMax: shareDetails.household_age_max,
    householdAgeMin: shareDetails.household_age_min,
    householdSex: shareDetails.household_sex,
    isFurnished: shareDetails.is_furnished,
    moveInFee: shareDetails.movein_fee,
    myPets: shareDetails.my_pets,
    mySmokingHabits: shareDetails.my_smoking_habits,
    occupation: shareDetails.occupation,
    overnightGuests: shareDetails.overnight_guests,
    parkingRent: shareDetails.parking_rent,
    partyHabits: shareDetails.party_habits,
    peopleInHousehold: shareDetails.people_in_household,
    petsPreference: shareDetails.pets_preference,
    roomAmenities: shareDetails.room_amenities,
    smokingPreference: shareDetails.smoking_preference,
    studentsOnly: shareDetails.students_only,
    utilitiesCost: shareDetails.utilities_cost,
    workSchedule: shareDetails.work_schedule,
  };
}

export function listingApartmentDetailsAdapter(apartmentDetails: ListingResponse['have_apartment'], apartmentAmenities: ListingResponse['apartment_amenities']): ApartmentDetails {
  return {
    apartmentAmenities: apartmentAmenities ?? null,
    aptSize: apartmentDetails?.apt_size ?? null,
    aptSizeUnits: apartmentDetails?.apt_size_units ?? null,
    bathroomsID: apartmentDetails?.bathrooms_id ?? null,
    bedroomsID: apartmentDetails?.bedrooms_id ?? null,
    isFurnished: apartmentDetails?.is_furnished ?? null,
  };
}

export default function listingAdapter(listingResponse: ListingResponse): Listing {
  const images = listingResponse.photos.map((photo) => photoAdapter(photo));
  const detailsRoom: Listing['detailsRoom'] = listingShareDetailsAdapter(listingResponse.share_details);
  const detailsApartmentSource = ListingTypeId[listingResponse.service_type] === 'haveapartment' ? listingResponse.have_apartment : listingResponse.need_apartment;
  const detailsApartment: Listing['detailsApartment'] = listingApartmentDetailsAdapter(detailsApartmentSource, listingResponse.apartment_amenities);

  return {
    addressFormatted: listingResponse.geo_location.google_formatted_address,
    addressFull: listingResponse.geo_location.full_address,
    addressVerificationStatus: listingResponse.address_check ? listingResponse.address_check as VerificationStatus : VerificationStatus.New,
    calendar: { dateIn: listingResponse.calendar.date_in },
    description: listingResponse.description,
    detailsApartment,
    detailsRoom,
    geoLocation: {
      latitude: listingResponse.geo_location.lat,
      latitudeNorthEast: listingResponse.geo_location.ne_lat,
      latitudeSouthWest: listingResponse.geo_location.sw_lat,
      longitude: listingResponse.geo_location.lng,
      longitudeNorthEast: listingResponse.geo_location.ne_lng,
      longitudeSouthWest: listingResponse.geo_location.sw_lng,
    },
    id: listingResponse.listing_id,
    images,
    isBookmarked: listingResponse.is_bookmarked,
    isNew: listingResponse.is_new,
    listingTypeId: listingResponse.service_type,
    location: {
      addressLine: listingResponse.geo_location.address_line,
      city: listingResponse.geo_location.city,
      country: listingResponse.geo_location.country,
      postalCode: listingResponse.geo_location.postal_code,
      region: listingResponse.geo_location.region,
    },
    rates: {
      currency: listingResponse.rates.currency,
      monthlyRate: listingResponse.rates.monthly_rate,
      requestedCurrency: listingResponse.rates.requested_currency,
      requestedMonthlyRate: listingResponse.rates.requested_monthly_rate,
    },
    refreshed: listingResponse.refreshed,
    title: listingResponse.headline,
  };
}
