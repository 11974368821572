import sendHttpError from '@/libs/logger/requests/send-http-error';

export default async function initUserIdVerification(userBearerToken: string) {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/v2/bgcheck/verification`;
  const headers: HeadersInit = { Authorization: `Bearer ${userBearerToken}` };

  const initVerificationResponse = await fetch(url, {
    cache: 'no-store',
    headers,
    method: 'POST',
  });

  // TODO: rewrite to flow without break APP
  if (!initVerificationResponse.ok) {
    sendHttpError({
      baseName: 'Init user id verifications by POST call',
      functionName: 'initUserIdVerification',
      method: 'POST',
      statusCode: initVerificationResponse.status,
      url,
    });

    throw new Error('Failed to fetch id verifications');
  }

  return true;
}
