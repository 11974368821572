import { type UsersIdVerificationCollectionResponse } from '@/features/user/types/user-id-verification-response-types';
import { type UserIdVerification } from '@/features/user/types/user-id-verification-types';

export default function userIdVerificationAdapter(userIdVerificationResponse: UsersIdVerificationCollectionResponse): UserIdVerification {
  const userVerification: UserIdVerification = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const [userId,
    userVerificationDetailsResponse] of Object.entries(userIdVerificationResponse.data)) {
    userVerification[Number(userId)] = userVerificationDetailsResponse ? {
      createdDate: new Date(userVerificationDetailsResponse.created_date),
      documentCountry: userVerificationDetailsResponse.document_country,
      documentType: userVerificationDetailsResponse.document_type,
      expiredDate: new Date(userVerificationDetailsResponse.expired_date),
      filledDate: userVerificationDetailsResponse.filled_date ? new Date(userVerificationDetailsResponse.filled_date) : null,
      // eslint-disable-next-line no-underscore-dangle
      id: userVerificationDetailsResponse._id,
      isExpired: userVerificationDetailsResponse.is_expired,
      paymentDate: new Date(userVerificationDetailsResponse.payment_date),
      status: userVerificationDetailsResponse.status,
      tosAgreedDate: new Date(userVerificationDetailsResponse.tos_agreed_date),
      updatedDate: new Date(userVerificationDetailsResponse.updated_date),
      userID: userVerificationDetailsResponse.user_id,
    } : null;
  }

  return userVerification;
}
