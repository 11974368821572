import listingAdapter from '@/features/listing/requests/adapters/listing-adapter';
import { type ListingsVerificationCollection } from '@/features/listing/types/listing-verification-types';
import { type UserListingsDataResponse } from '@/features/listing/types/user-listings-response-types';
import { type UserListingsData } from '@/features/listing/types/user-listings-types';

export default function userListingsAdapter(userListingsResponse: UserListingsDataResponse, userListingsVerification: ListingsVerificationCollection | null): UserListingsData {
  const userListings = userListingsResponse.listings.map((userListing) => {
    let isDataMissingListing = false;

    if (userListing.missing_data && userListing.missing_data.length > 0) {
      isDataMissingListing = true;
    }

    if (userListingsVerification?.[userListing.listing_id]?.status) {
      return {
        ...listingAdapter(userListing),
        // @ts-expect-error - ts is shit sometimes
        addressVerificationStatus: userListingsVerification[userListing.listing_id].status,
        isDataMissing: isDataMissingListing,
        missingDataFields: userListing.missing_data,
        verificationDetails: userListingsVerification[userListing.listing_id],
      };
    }

    return {
      ...listingAdapter(userListing),
      isDataMissing: isDataMissingListing,
      missingDataFields: userListing.missing_data,
      verificationDetails: null,
    };
  });

  const uniqueListings = [...new Map(userListings.map((item) => [item.id, item])).values()];
  // if one listing and listing type is haveapartment or haveshare
  // const isAddressVerificationShow = uniqueListings.length === 1 && (uniqueListings[0].listingTypeId === ListingTypeId.haveapartment || uniqueListings[0].listingTypeId === ListingTypeId.haveshare);
  const isAddressVerificationShow = uniqueListings.length > 0;

  return {
    count: userListingsResponse.count,
    isAddressVerificationShow,
    isDataMissing: userListingsResponse.is_data_missing,
    listings: userListings,
  };
}
