import userIdVerificationAdapter from '@/features/user/requests/adapters/user-id-verification-adapter';
import initUserIdVerification from '@/features/user/requests/init-user-id-verification';
import { type UsersIdVerificationCollectionResponse } from '@/features/user/types/user-id-verification-response-types';
import { type UserIdVerification } from '@/features/user/types/user-id-verification-types';
import { type User } from '@/features/user/types/user-types';
import sendHttpError from '@/libs/logger/requests/send-http-error';

interface UsersIdVerificationsPublicProps {
  type: 'public',
  usersIds: User['id'][],
}

interface UsersIdVerificationsPrivateProps {
  type: 'private',
  bearerToken: string,
  userId: User['id'],
}

type UsersIdVerificationsProps = UsersIdVerificationsPublicProps | UsersIdVerificationsPrivateProps;

export default async function getUsersIdVerifications(verificationsProps: UsersIdVerificationsProps): Promise<UserIdVerification> {
  const urlPart = verificationsProps.type === 'public' ? verificationsProps.usersIds.join(',') : String(verificationsProps.userId);
  const url = `${process.env.NEXT_PUBLIC_API_URL}/v2/bgcheck/verification/${urlPart}?filter=public`;
  const headers: HeadersInit = {};

  if (verificationsProps.type === 'private') {
    headers.Authorization = `Bearer ${verificationsProps.bearerToken}`;
  }

  let usersVerificationsResponse = await fetch(url, {
    cache: 'no-store',
    headers,
    method: 'GET',
  });

  if (usersVerificationsResponse.status === 404 && verificationsProps.type === 'private') {
    const isInitVerificationSuccess = await initUserIdVerification(verificationsProps.bearerToken);

    if (!isInitVerificationSuccess) {
      return { [verificationsProps.userId]: null };
    }

    usersVerificationsResponse = await fetch(url, {
      cache: 'no-store',
      headers,
      method: 'GET',
    });
  }

  // TODO: rewrite to flow without break APP
  if (!usersVerificationsResponse.ok) {
    sendHttpError({
      baseName: 'Get users id verifications',
      functionName: 'getUsersIdVerifications',
      method: 'GET',
      statusCode: usersVerificationsResponse.status,
      url,
    });

    throw new Error('Failed to fetch id verifications - getUsersIdVerifications');
  }

  const usersVerificationsResponseData = await usersVerificationsResponse.json() as UsersIdVerificationCollectionResponse;

  if (verificationsProps.type === 'private' && usersVerificationsResponseData.data[verificationsProps.userId] === null) {
    const isInitVerificationSuccess = await initUserIdVerification(verificationsProps.bearerToken);

    if (!isInitVerificationSuccess) {
      return { [verificationsProps.userId]: null };
    }

    const usersVerificationsRetryResponse = await fetch(url, {
      cache: 'no-store',
      headers,
      method: 'GET',
    });

    const usersVerificationsRetryResponseData = await usersVerificationsRetryResponse.json() as UsersIdVerificationCollectionResponse;

    return userIdVerificationAdapter(usersVerificationsRetryResponseData);
  }

  return userIdVerificationAdapter(usersVerificationsResponseData);
}
